<template>
	<v-chip
		:outlined="outlined"
		:class="customClass"
		:color="color"
		:text-color="textColor"
		:small="small"
		:x-small="xSmall"
		v-on="vOn"
		v-bind="vBindAttrs"
		label
		:style="[{ width: `${width ? width + 'px' : ''}` }, inlineStyle]"
	>
		{{ text }}
	</v-chip>
</template>

<script>
export default {
	name: "btx-chip",
	props: {
		width: {
			type: String,
			default: null,
		},
		inlineStyle: {
			type: [String, Object],
			default: () => {},
		},
		color: {
			type: String,
			default: "cyan",
		},
		textColor: {
			type: String,
			default: "white",
		},
		text: {
			type: [String, Number],
			default: null,
		},
		customClass: {
			type: [String, Array],
			default: null,
		},
		vOn: {
			type: Object,
			default: () => {
				return {};
			},
		},
		vBindAttrs: {
			type: Object,
			default: () => {
				return {};
			},
		},
		small: {
			type: Boolean,
			default: false,
		},
		xSmall: {
			type: Boolean,
			default: false,
		},
		outlined: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
